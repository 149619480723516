import { Grid, Tab, Tabs, useMediaQuery, useTheme } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TabContext, TabPanel } from '@mui/lab';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';
import EventParticipantsTable from './EventParticipantsTable';
import { EnrichedEventData, OrderData, WaitingListItemData, WaitingListService } from '../../../../api';
import { CellProps, useTable } from '../../../../table/useTable';

export const waitingListFetcher =
  (eventId: string) =>
  async ({ search, page, perPage }: { search?: string; page: number; perPage: number }) =>
    WaitingListService.getWaitingListByEvent(eventId, page, perPage, search);

type TablesTabsProps = {
  event: EnrichedEventData;
  orderPage: any;
  setOrder: (order: OrderData) => void;
  setEditOrderIsOpen: (isOpen: boolean) => void;
  setCheckInDialogIsOpen: (isOpen: boolean) => void;
  isInEventMode: boolean;
};
const StyledWaitingListNotEmpty = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
`;
export const TablesTabs = ({
  event,
  orderPage,
  setOrder,
  setEditOrderIsOpen,
  setCheckInDialogIsOpen,
  isInEventMode,
}: TablesTabsProps) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { t } = useTranslation();
  const height = window.innerHeight;
  const [activeTab, setActiveTab] = useState('0');
  const handleChange = (_: any, newValue: string) => {
    setActiveTab(newValue);
  };
  const tableDetails: CellProps<WaitingListItemData>[] = [
    {
      id: 'name',
      alignment: 'inherit',
      label: t('waitingList.name'),
      render: (waitingListItem: WaitingListItemData) => `${waitingListItem.firstname} ${waitingListItem.lastname}`,
    },
    {
      id: 'persons',
      alignment: 'center',
      label: t('waitingList.persons'),
      render: (waitingListItem: WaitingListItemData) => waitingListItem.persons,
    },
    {
      id: 'phone',
      alignment: 'center',
      label: t('waitingList.phone'),
      render: (waitingListItem: WaitingListItemData) => waitingListItem.phone,
    },
    {
      id: 'email',
      alignment: 'center',
      label: t('waitingList.email'),
      render: (waitingListItem: WaitingListItemData) => (
        <Typography style={{ textTransform: 'none' }}>{waitingListItem.email}</Typography>
      ),
    },
  ];
  const { TableComponent, data, isLoadingData } = useTable<WaitingListItemData>({
    tableDetails,
    hidePaging: true,
    defaultRowsPerPage: 1000,
    opId: 'getWaitingList',
    fetcher: waitingListFetcher(event.id),
  });
  const shouldShowNoResultsFound = !isLoadingData && !data.length;

  // fetching waiting list to show in the tab

  return (
    <TabContext value={activeTab}>
      {!isInEventMode && (
        <Tabs value={activeTab} onChange={handleChange}>
          <Tab
            label={
              <Grid container flexDirection="column">
                {t('ordersTabs.orders')}
              </Grid>
            }
            value="0"
          />
          <Tab
            label={
              <Grid container gap={0.5}>
                {data.length > 0 && (
                  <Grid>
                    <StyledWaitingListNotEmpty style={{ backgroundColor: '#ffa726' }} />
                  </Grid>
                )}
                <Grid>{t('ordersTabs.waitingList')}</Grid>
              </Grid>
            }
            value="1"
          />
        </Tabs>
      )}
      <TabPanel value="0" style={{ padding: 0 }}>
        <Grid
          container
          flexDirection="column"
          style={{ height: isSmallScreen ? undefined : '60vh', overflow: 'hidden' }}>
          <EventParticipantsTable
            setOrder={setOrder}
            event={event}
            orderPage={orderPage}
            setEditOrderIsOpen={setEditOrderIsOpen}
            setCheckInDialogIsOpen={setCheckInDialogIsOpen}
            isInEventMode={isInEventMode}
          />
        </Grid>
      </TabPanel>
      <TabPanel value="1" style={{ padding: 0 }}>
        <Grid container flexDirection="column" style={{ height: height < 674 ? '50vh' : '60vh', overflow: 'hidden' }}>
          <Grid
            container
            flexDirection="column"
            className="event-participant-table"
            overflow="auto"
            mb={4}
            border="1px solid #555555"
            borderRadius="4px">
            {shouldShowNoResultsFound ? (
              <Grid container m={4}>
                {t('waitingList.nonfound')}
              </Grid>
            ) : (
              TableComponent
            )}
          </Grid>{' '}
        </Grid>
      </TabPanel>
    </TabContext>
  );
};
