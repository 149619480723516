import * as React from 'react';
import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import { Engineering, EventBusy, EventRepeat } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import { useAuth } from '../../session/InternalAuthProvider';
import './CalendarSpeedDial.scss';
import { useCalendar } from '../CalendarContext';
import { accountHasEventOrderPages } from '../../common/AccountUtils';
import { UseAuthPermissions } from '../../session/UseAuthPermissions';
import { AccountData } from '../../api';

type CalendarSpeedDialProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  setShiftDialogOpen: (open: boolean) => void;
  setUnavailabilityDialogOpen: (open: boolean) => void;
};

enum ActionType {
  addOrder = 'addOrder',
  addSlot = 'addSlot',
  addUnavailability = 'addUnavailability',
  addShift = 'addShift',
}

type Action = {
  icon: React.ReactNode;
  label: string;
  actionType: ActionType;
};
export default function CalendarSpeedDial({
  open,
  setOpen,
  setUnavailabilityDialogOpen,
  setShiftDialogOpen,
}: CalendarSpeedDialProps) {
  const { readOnly } = UseAuthPermissions();
  const { t } = useTranslation();
  // @ts-ignore
  const { authState } = useAuth();

  const account = authState.account as unknown as AccountData;
  // @ts-ignore
  const { openEditOrderDialog, openEditEventDialog } = useCalendar();
  const hasEventOrderPages = accountHasEventOrderPages(authState.account);

  const actions: Action[] = [
    {
      icon: <ConfirmationNumberIcon />,
      label: t('add_order'),
      actionType: ActionType.addOrder,
    },
    {
      icon: <EventBusy />,
      label: t('calendar.add_unavailability'),
      actionType: ActionType.addUnavailability,
    },
    {
      icon: <Engineering />,
      label: account.shifts_as_employee_unavailability
        ? t('calendar.add_employee_unavliability')
        : t('calendar.add_shift'),
      actionType: ActionType.addShift,
    },
  ];

  if (hasEventOrderPages) {
    actions.push({
      icon: <EventRepeat />,
      label: t('add_slots'),
      actionType: ActionType.addSlot,
    });
  }

  const employeeActions: Action[] = [
    {
      icon: <Engineering />,
      label: t('calendar.add_shift'),
      actionType: ActionType.addShift,
    },
  ];

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleClick = (e: any, action: Action) => {
    e.preventDefault();
    switch (action.actionType) {
      case ActionType.addSlot:
        openEditEventDialog();
        break;
      case ActionType.addUnavailability:
        setUnavailabilityDialogOpen(true);
        break;
      case ActionType.addShift:
        setShiftDialogOpen(true);
        break;
      case ActionType.addOrder:
        openEditOrderDialog();
        break;
      default:
    }
    handleClose();
  };

  return (
    <Box
      sx={{
        width: open ? '100%' : 'auto',
        height: open ? '100%' : 'auto',
        position: 'fixed',
        bottom: 0,
        right: 0,
        transform: 'translateZ(0px)',
        flexGrow: 1,
        zIndex: 100,
      }}>
      <Backdrop
        open={open}
        sx={{
          width: open ? '100%' : 'auto',
          height: open ? '100%' : 'auto',
          zIndex: 100,
        }}
      />
      <SpeedDial
        className="calendar-speed-dial"
        sx={{ position: 'fixed', bottom: 16, right: 16 }}
        ariaLabel="SpeedDial"
        icon={<SpeedDialIcon />}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}>
        {(readOnly ? employeeActions : actions).map((action) => (
          <SpeedDialAction
            key={action.label}
            icon={action.icon}
            tooltipTitle={action.label}
            tooltipOpen
            onClick={(e) => handleClick(e, action)}
          />
        ))}
      </SpeedDial>
    </Box>
  );
}
