import { Navigate } from 'react-router-dom';
import React from 'react';
import { AuthProvider } from '@propelauth/react';
import { Box, ThemeProvider } from '@mui/material';
import { GoogleOAuthProvider } from '@react-oauth/google';
import MainLayout from '../dashboard/MainLayout';
import OrderPage from '../order_page/OrderPage';
import { OrderPageProvider } from '../order_page/OrderPageContext';
import OrderCompletedPage from '../order_page/orderCompletion/OrderCompletedPage';
import Orders from '../orders/Orders';
import Calendar from '../calendar/CalendarWrapper';
import { CalendarProvider } from '../calendar/CalendarContext';
import OrderPages from '../orders/OrderPages';
import HomePage from '../marketingsite/HomePage';
import BusinessPage from '../marketplace/BusinessPage';
import ChooseOrderPage from '../order_page/ChooseOrderPage';
import { CommonDialogsProvider } from './dialogs/CommonDialogsContext';
import WaitingList from '../order_page/WaitingList';
import WaitingListCompletedPage from '../order_page/WaitingListCompletedPage';
import AuthGuard from '../session/AuthGuard';
import InternalAuthProvider from '../session/InternalAuthProvider';
import { CustomerFacingWidgetWrapper } from './ui/CustomerFacingWidgetWrapper';
import { ManageBookingWizard } from '../manageBookingPage/ManageBookingWizard';
import { PaymentLinksWizard } from '../paymentLinksPage/PaymentLinksPage';
import MarketPlaceMain, { homePageTheme } from '../marketplace/MarketPlaceMain';
import MarketPlaceHeader from '../marketplace/MarketPlaceHeader';
import VouchersTable from '../vouchers/VouchersTable';
import { TermsComponent } from '../terms/Terms';
import { UserSettingsPage } from '../userSettings/UserSettingsPage';
import { OrderPageSettingsPage } from '../settings/OrderPageSettingsPage';
import { AccountSettingsPage } from '../accountSettings/AccountSettingsPage';
import { PrivacyComponent } from '../terms/Privacy';
import { WaitingListThemeWrapper } from '../order_page/WaitingListThemeWrapper';
import { GATest } from './GATest';
import { UserAvailabilityPage } from '../userSettings/UserAvailabilityPage';
import { EmailTemplatesPage } from '../emailTemplates/EmailTemplatesPage';
import { DiscountsPage } from '../discounts/DiscountsPage';
import { CreateExperiencePage } from '../createExperiencePage/CreateExperiencePage';
import { ReportsPage } from '../reports/ReportsPage';

const routes = [
  {
    element: (
      <AuthProvider authUrl={import.meta.env.VITE_REACT_APP_AUTH_URL}>
        <InternalAuthProvider>
          <AuthGuard>
            <GoogleOAuthProvider clientId="432245203095-crb38f5c51ae987r0vkguv5o9h95gb85.apps.googleusercontent.com">
              <CommonDialogsProvider>
                <MainLayout />
              </CommonDialogsProvider>
            </GoogleOAuthProvider>
          </AuthGuard>
        </InternalAuthProvider>
      </AuthProvider>
    ),
    children: [
      {
        path: '/dashboard/calendar/:startDate?',
        element: (
          <CalendarProvider>
            <Calendar />
          </CalendarProvider>
        ),
      },
      {
        path: '/dashboard/orders',
        element: (
          <CalendarProvider>
            <Orders />
          </CalendarProvider>
        ),
      },
      {
        path: '/dashboard/vouchers',
        element: (
          <CalendarProvider>
            <VouchersTable />
          </CalendarProvider>
        ),
      },
      {
        path: '/dashboard/discounts',
        element: <DiscountsPage />,
      },
      {
        path: '/dashboard/create-experience',
        element: <CreateExperiencePage />,
      },
      {
        path: '/dashboard/user-settings',
        element: <UserSettingsPage />,
      },
      {
        path: '/dashboard/user-availability',
        element: <UserAvailabilityPage />,
      },
      {
        path: '/dashboard/account-settings',
        element: <AccountSettingsPage />,
      },
      {
        path: '/dashboard/email-templates',
        element: <EmailTemplatesPage />,
      },
      {
        path: '/dashboard/settings/:orderPageId',
        element: <OrderPageSettingsPage />,
      },
      { path: '/dashboard/order-pages', element: <OrderPages /> },

      {
        path: '/dashboard/reports',
        element: <ReportsPage />,
      },
    ],
  },
  {
    path: '/market/page/:marketPlaceItemId',
    element: (
      <Box className="market-place-base">
        <ThemeProvider theme={homePageTheme}>
          <MarketPlaceHeader activeTopBar withReturnButton secondaryWhatsappButton />
        </ThemeProvider>
        <Box sx={{ marginTop: '60px' }}>
          <BusinessPage />
        </Box>
      </Box>
    ),
  },
  {
    path: '/',
    element: (
      <AuthProvider authUrl={import.meta.env.VITE_REACT_APP_AUTH_URL}>
        <InternalAuthProvider>
          <HomePage />
        </InternalAuthProvider>
      </AuthProvider>
    ),
  },
  { path: '/vouchers', element: <MarketPlaceMain /> },
  { path: '/dashboard', element: <Navigate to="/dashboard/calendar" /> },
  {
    path: '/dashboard/default',
    element: <Navigate to="/dashboard/calendar" />,
  },
  { path: '/order/completed', element: <OrderCompletedPage /> },
  { path: '/waiting-list/completed', element: <WaitingListCompletedPage /> },
  {
    path: '/choose-order/:accountId/',
    element: <ChooseOrderPage />,
  },
  {
    path: '/order/:accountId/:experienceId/:orderPageId',
    element: (
      <OrderPageProvider>
        <OrderPage />
      </OrderPageProvider>
    ),
  },
  {
    path: '/order/:accountId/:experienceId',
    element: (
      <OrderPageProvider>
        <OrderPage />
      </OrderPageProvider>
    ),
  },
  {
    path: '/order/:accountId',
    element: (
      <OrderPageProvider>
        <OrderPage />
      </OrderPageProvider>
    ),
  },
  {
    path: '/waiting-list/:accountId/:experienceId/:orderPageId/:persons/:date',
    element: (
      <WaitingListThemeWrapper>
        <WaitingList />
      </WaitingListThemeWrapper>
    ),
  },
  {
    path: '/manage-booking',
    element: (
      <CustomerFacingWidgetWrapper>
        <ManageBookingWizard />
      </CustomerFacingWidgetWrapper>
    ),
  },
  {
    path: '/payment-link',
    element: (
      <CustomerFacingWidgetWrapper>
        <PaymentLinksWizard />
      </CustomerFacingWidgetWrapper>
    ),
  },
  {
    path: '/terms',
    element: <TermsComponent />,
  },

  {
    path: '/gatest',
    element: <GATest />,
  },
  {
    path: '/privacy',
    element: <PrivacyComponent />,
  },
  { path: '*', element: <div>Not found</div> },
];

export default routes;
