import { useTranslation } from 'react-i18next';
import { AccordionDetails, Grid, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { UsersMultiSelect } from '../../../../components/UsersMultiSelect';
import { AccountData, PropelUserModel } from '../../../../../api';
import { Accordion, AccordionPanelsNames, AccordionSummary } from '../Accordion';
import { ManagementPanelSummary } from './ManagementPanelSummary';
import CopyToClipboardButton from '../../../CopyToClipboardButton';
import { useAuth } from '../../../../../session/InternalAuthProvider';

type ManagementPanelProps = {
  isEventOrderPage: boolean;
  users: PropelUserModel[];
  selectedUsersIds: string[];
  setSelectedUsersIds: (ids: string[]) => void;
  comment?: string;
  setComment: (comment: string) => void;
  expanded?: AccordionPanelsNames;
  handleChange: any;
  orderId?: string;
  isOneOfVoucherIntents: boolean;
};
export const ManagementPanel = ({
  isEventOrderPage,
  users,
  selectedUsersIds,
  setSelectedUsersIds,
  comment,
  setComment,
  expanded,
  handleChange,
  orderId,
  isOneOfVoucherIntents,
}: ManagementPanelProps) => {
  const { t } = useTranslation();
  const { authState } = useAuth();

  const [internalComment, setInternalComment] = useState(comment);
  const employeesFeatureEnabled = useFeatureFlagEnabled('employees-feature-enabled');

  return (
    <Accordion onChange={handleChange} expandedPanelName={expanded} panelName={AccordionPanelsNames.ManagementPanel}>
      <AccordionSummary
        title={t('calendar.edit_order.magmntSection')}
        summaryComponent={
          <ManagementPanelSummary
            shouldHideUsers={isOneOfVoucherIntents || !employeesFeatureEnabled}
            comment={internalComment}
            selectedUsersIds={selectedUsersIds}
            users={users}
            isEventOrderPage={isEventOrderPage}
          />
        }
      />
      <AccordionDetails>
        <Grid container flexDirection="column" gap={2}>
          {!isEventOrderPage && employeesFeatureEnabled && !isOneOfVoucherIntents && (
            <UsersMultiSelect
              users={users}
              selectedUsersIds={selectedUsersIds}
              setSelectedUsersIds={setSelectedUsersIds}
            />
          )}
          <TextField
            label={t('calendar.edit_event.comments')}
            type="text"
            value={comment}
            onChange={(e) => {
              setInternalComment(e.target.value);
              setComment(e.target.value);
            }}
          />
          {orderId && (authState.account as unknown as AccountData).returning_customer_settings && (
            <Grid container gap={0.5} flexDirection="column" borderRadius="8px" border="1px solid #c4c4c4" p={1}>
              <Typography>{`${t('calendar.order_popper.returningCsutomer')}:`}</Typography>
              <Grid container gap={1} alignItems="center">
                {orderId}
                <CopyToClipboardButton width={'18px'} color="grey" text={orderId} />
              </Grid>
            </Grid>
          )}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};
