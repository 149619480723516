import {
  ClickAwayListener,
  Collapse,
  FormControlLabel,
  Grid,
  Switch,
  Tooltip,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, { useState } from 'react';
import {
  Close,
  Delete,
  Edit,
  Email,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  Link,
  PersonAddAlt,
  Timeline,
} from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import '../OrderBoxPopper.scss';
import { useTranslation } from 'react-i18next';
import { usePostHog } from 'posthog-js/react';
import Typography from '@mui/material/Typography';
import { useCalendar } from '../../CalendarContext';
import { useAuth } from '../../../session/InternalAuthProvider';
import { getOrderPage } from '../../../common/AccountUtils';
import { useCommonDialogs } from '../../../common/dialogs/CommonDialogsContext';
import ResponsiveDialog from '../../../common/ui/ResponsiveDialog';
import { UseAuthPermissions } from '../../../session/UseAuthPermissions';
import EditOrderDialog from '../../dialogs/EditOrderDialog/EditOrderDialog';
import SendEmailDialog from '../../dialogs/SendEmailDialog/SendEmailDialog';
import { EnrichedEventData, OrderData } from '../../../api';
import { TablesTabs } from './components/TablesTabs';
import CheckinDialog from '../../dialogs/CheckinDialog/CheckinDialog';
import CopyToClipboardButton from '../../dialogs/CopyToClipboardButton';
import { useEventOrders } from '../../swr/useEventOrders';
import { EventBoxDialogInfoHeader } from './components/EventBoxDialogInfoHeader';
import { useLanguage } from '../../../common/GeneralUtils';

type EventBoxDialogProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  event: EnrichedEventData;
  openEvent: (event: EnrichedEventData) => void;
  getNextEvent: () => EnrichedEventData | undefined;
  getPreviousEvent: () => EnrichedEventData | undefined;
};
const EventBoxDialog = ({ open, setOpen, event, openEvent, getNextEvent, getPreviousEvent }: EventBoxDialogProps) => {
  const { t } = useTranslation();
  const {
    // @ts-ignore
    openEditEventDialog,
    // @ts-ignore
    openDeleteEventDialog,
    // @ts-ignore
    deleteOrderDialogState,
    // @ts-ignore
    editOrderDialogState,
  } = useCalendar();
  const posthog = usePostHog();
  const { addOrder, updateOrder } = useEventOrders({ eventId: event.id });
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  // @ts-ignore
  const { openActivityDialog } = useCommonDialogs();
  const { authState } = useAuth();
  const [order, setOrder] = useState<OrderData | undefined>(undefined);
  const orderPage = getOrderPage(authState.account, event.order_page_id);
  const { readOnly } = UseAuthPermissions();
  const [editOrderIsOpen, setEditOrderIsOpen] = useState(false);
  const [sendMessageDialogIsOpen, setSendMessageDialogIsOpen] = useState(false);
  const [checkInDialogIsOpen, setCheckInDialogIsOpen] = useState(false);
  // when in event we want to show only the table with no tabs or info
  const [isInEventMode, setIsInEventMode] = React.useState(false);
  const language = useLanguage();
  const nextEvent = getNextEvent();
  const previousEvent = getPreviousEvent();
  const handleExpandClick = () => {
    setIsInEventMode(!isInEventMode);
  };

  return (
    <ResponsiveDialog
      fullScreen
      isOpen={open}
      setOpen={setOpen}
      content={
        <ClickAwayListener
          onClickAway={() => {
            if (
              !editOrderIsOpen &&
              !editOrderDialogState.isOpen &&
              !deleteOrderDialogState.isOpen &&
              !checkInDialogIsOpen
            ) {
              setOpen(false);
              setEditOrderIsOpen(false);
            }
          }}>
          <Grid>
            <Grid container flexDirection="column" px={2} pt={2} overflow="hidden" flexWrap="nowrap">
              <Grid container flexDirection="column" justifyContent="space-between">
                <Grid item display="flex" justifyContent="flex-end" alignItems="start">
                  <Grid
                    container
                    flexDirection={language === 'he' ? 'row-reverse' : 'row-reverse'}
                    justifyContent={'flex-end'}>
                    <Tooltip title={t('next')} arrow>
                      <span>
                        <IconButton disabled={!nextEvent} onClick={() => openEvent(nextEvent!)}>
                          {language === 'he' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                        </IconButton>
                      </span>
                    </Tooltip>
                    <Tooltip title={t('previous')} arrow>
                      <span>
                        <IconButton disabled={!previousEvent} onClick={() => openEvent(previousEvent!)}>
                          {language === 'he' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                        </IconButton>
                      </span>
                    </Tooltip>
                  </Grid>
                  <Tooltip title={readOnly ? t('noPermissions') : t('add')} arrow>
                    <span>
                      <IconButton disabled={readOnly} onClick={() => setEditOrderIsOpen(true)}>
                        <PersonAddAlt />
                      </IconButton>
                    </span>
                  </Tooltip>
                  <Tooltip title={readOnly ? t('noPermissions') : t('calendar.event_popper.link_to_sell')} arrow>
                    <span>
                      <IconButton
                        disabled={readOnly}
                        onClick={() =>
                          posthog.capture('Copy link to event', {
                            accountId: event.account_id,
                            eventId: event.id,
                          })
                        }>
                        <CopyToClipboardButton
                          IconComponent={Link}
                          text={`${window.location.origin}/order/${event.account_id}/${event.experience_id}/${event.order_page_id}?event=${event.id}`}
                        />
                      </IconButton>
                    </span>
                  </Tooltip>
                  <Tooltip title={readOnly ? t('noPermissions') : t('sendMessage')} arrow>
                    <span>
                      <IconButton disabled={readOnly} onClick={() => setSendMessageDialogIsOpen(true)}>
                        <Email />
                      </IconButton>
                    </span>
                  </Tooltip>
                  <Tooltip title={readOnly ? t('noPermissions') : t('delete')} arrow>
                    <span>
                      <IconButton
                        disabled={readOnly}
                        onClick={() => {
                          openDeleteEventDialog(event);
                          setOpen(false);
                        }}>
                        <Delete />
                      </IconButton>
                    </span>
                  </Tooltip>
                  <Tooltip title={readOnly ? t('noPermissions') : t('edit')} arrow>
                    <span>
                      <IconButton
                        disabled={readOnly}
                        onClick={() => {
                          openEditEventDialog(event, event.order_page_id);
                          setOpen(false);
                        }}>
                        <Edit />
                      </IconButton>
                    </span>
                  </Tooltip>
                  <Tooltip title={readOnly ? t('noPermissions') : t('activity.activity_timeline')} arrow>
                    <span>
                      <IconButton
                        disabled={readOnly}
                        onClick={() => {
                          openActivityDialog('event', { eventId: event.id });
                          setOpen(false);
                        }}>
                        <Timeline />
                      </IconButton>
                    </span>
                  </Tooltip>

                  <IconButton
                    onClick={() => {
                      setOpen(false);
                    }}>
                    <Tooltip title={t('close')} arrow>
                      <Close />
                    </Tooltip>
                  </IconButton>
                </Grid>
                {isSmallScreen && (
                  <FormControlLabel
                    control={
                      <Switch
                        checked={isInEventMode}
                        onChange={handleExpandClick}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                    }
                    label={<Typography>{t('orders.eventView')}</Typography>}
                  />
                )}
                <Collapse in={!isInEventMode} timeout="auto" unmountOnExit>
                  <EventBoxDialogInfoHeader event={event} />
                </Collapse>
              </Grid>
            </Grid>
            <Grid p={2} pt={isSmallScreen ? 0 : 2}>
              <TablesTabs
                setCheckInDialogIsOpen={setCheckInDialogIsOpen}
                setOrder={setOrder}
                event={event}
                orderPage={orderPage}
                setEditOrderIsOpen={setEditOrderIsOpen}
                isInEventMode={isInEventMode}
              />
            </Grid>
            <SendEmailDialog
              isOpen={sendMessageDialogIsOpen}
              setIsOpen={setSendMessageDialogIsOpen}
              eventId={event.id}
            />
            <CheckinDialog
              order={order}
              isOpen={checkInDialogIsOpen}
              setIsOpen={setCheckInDialogIsOpen}
              updateItem={updateOrder}
            />
            <EditOrderDialog
              editOrderDialogState={{
                order,
                isOpen: editOrderIsOpen,
                orderPageId: orderPage.id,
                parentId: event.id,
                parent: event,
              }}
              closeEditOrderDialog={() => {
                setEditOrderIsOpen(false);
                setOrder(undefined);
              }}
              addOrder={(orderToUpsert) => addOrder(orderToUpsert)}
              updateOrder={(orderToUpdate) => updateOrder(orderToUpdate)}
            />
          </Grid>
        </ClickAwayListener>
      }
    />
  );
};

export default EventBoxDialog;
